.App {
  text-align: left;
  color: white;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
}

.App::-webkit-scrollbar {  /* Chrome, Safari 対応 */
    display:none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  /* min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input {
  background-color: black;
  color: #fff;
  border-style: none
}

.terminal-input-line {
  display: flex;
}

.hostname {
  color: #0F0;
}

.current-directory {
  color: #4169E1;
}

.terminal-input-header {
  display: flex;
}

.terminal-line {
  display: flex;
}

.stdin {
  outline: 0;
  width: 100vw;
}

input::-webkit-calendar-picker-indicator {
    display: none;
}

.face-ascii-art {
  background-color: white;
  color: black;
}

ol { 
  counter-reset: item;
  list-style-type: none;
}
li { display: block; }
li:before { 
  content: counter(item) "　"; 
  counter-increment: item 
}

.job-status {
  padding-right: 40px;
}